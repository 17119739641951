import React from 'react'
import { PopupBody, PopupBodySubHeadline } from './PopupBase'
import LinkText from '../../LinkText';
import LinkImg from '../../LinkImg';

type Props = {
  fontResizeRate: number;
}

const PopupVok: React.FC<Props> = ({ fontResizeRate }) => {
  return (
    <div>
      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        About
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        はじめまして、森あんじゅと申します。粋で心地の良いデザインを日々模索する、アートディレクター・グラフィックデザイナーです。
        <br /><br />
        自身のロゴコンセプト「am I am　はじまりをみる。ありたい姿をかたちにする。」に込めた通り、ご依頼のはじまりにある想いやあらゆる物事を紐解き、ありたい姿の輪郭を捉え日々制作に励んでいます。風呂敷を広げたり、時には折りたたんで深めたりするような双方向の対話を大切にしています。ぜひ、お話聞かせてください。
        <br /><br />
        そして対話から本質を掬い、造形として魅力を感じる「かたち」へデザインや言葉を通して落とし込んでいきます。そうして出来たかたちは機能することはもちろん、まるでお守りのように活動の支えや活力になってくれると信じています。
        <br /><br />
        このサイトは自身と対話を重ね、大切にしたい姿勢や価値観を軸に制作しました。テーマは、とことん胸を張ってお気に入りだと言えること。ベーシックな装いの中にひとひねりの個性がある「ひとひねりのあるベーシック」なデザインに仕上がりました。置いてある家具や植物は実際にうちにあるお気に入りたちを元にしています。
        <br /><br />
        細部のこだわりを語り始めたらキリがないので、お会いした時にどうぞお話しさせてください。webサイトの実装はKeita Miyanoです。たくさんの対話を重ね、きめ細かに一緒にものづくりしていただきました。本当にありがとう。
        <br /><br />
        webサイトをご覧いただき、また、ここまでお読みいただきありがとうございます。もし何かお力になれそうなことがございましたら、ぜひお声掛けいただけると嬉しいです。
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        Contact
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        お問い合わせは、以下リンクよりお願いいたします。
        <br />
        <LinkText url="https://forms.gle/TAb4xMGVy43axL4V9" customText="[Contact us]" />
      </PopupBody>

      <PopupBodySubHeadline fontResizeRate={fontResizeRate}>
        SNS
      </PopupBodySubHeadline>

      <PopupBody fontResizeRate={fontResizeRate}>
        <div style={{ display: "flex", height: 28 * fontResizeRate }}>
          <LinkImg
            src="https://firebasestorage.googleapis.com/v0/b/anjumori-f0a76.appspot.com/o/sns%2FInstagram_Glyph_Black.png?alt=media"
            width={28 * fontResizeRate}
            url="https://www.instagram.com/anj_126/"
          />
          <div style={{ paddingRight: 16 * fontResizeRate }} />
          <LinkImg
            src="https://firebasestorage.googleapis.com/v0/b/anjumori-f0a76.appspot.com/o/sns%2Flogo-black.png?alt=media"
            width={28 * fontResizeRate * 0.9}
            url="https://x.com/anj_126"
          />
        </div>
      </PopupBody>
    </div>
  )
}

export default PopupVok
